@use '../../scss/variables/colors' as *;

.u-progress {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 25px;

  &-bar {
    height: 10px !important;

    .progress-bar {
      background: $teal;
    }
  }

  &-percentage {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: $teal;
    margin-left: 8px;
  }
}
