@use '../../scss/variables/colors' as *;
@use '../../scss/utils/include-media' as *;

.u-button {
  width: fit-content;
  border-radius: 999px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;

  &-green,
  &-green:hover {
    background-color: $green;

    &.u-button-solid {
      border: 2px solid $green;
    }
  }

  &-teal,
  &-teal:hover {
    background-color: $teal;

    &.u-button-solid {
      border: 2px solid $teal;
    }
  }

  &-beige,
  &-beige:hover {
    background-color: $beige;

    &.u-button-solid {
      border: 2px solid $beige;
    }
  }

  &-white,
  &-white:hover {
    background-color: $white;

    &.u-button-solid {
      border: 2px solid $white;
    }
  }

  &-yellow,
  &-yellow:hover {
    background-color: $yellow;

    &.u-button-solid {
      border: 2px solid $yellow;
    }
  }

  &-accent-green {
    color: $green;

    &.u-button-outlined {
      border: 2px solid $green;
    }
  }

  &-accent-teal {
    color: $teal;

    &.u-button-outlined {
      border: 2px solid $teal;
    }
  }

  &-accent-white {
    color: $white;

    &.u-button-outlined {
      border: 2px solid $white;
    }
  }

  &-accent-yellow {
    color: $yellow;

    &.u-button-outlined {
      border: 2px solid $yellow;
    }
  }

  &-sm {
    padding: 12px 25px;
    font-weight: 600;
    font-size: 16px;
    line-height: 105%;

    @include media('<=large') {
      padding: 9px 12px;
      font-size: 10.8px;
    }
  }

  &-lg {
    padding: 17px 25px;
    font-weight: 600;
    font-size: 18px;
    line-height: 105%;

    @include media('<=large') {
      padding: 13px 23px;
      font-size: 14.58px;
    }
  }
}
