.sso-outer-container {
  display: flex;
  justify-content: center;

  .sso-inner-container {
    display: flex;
    justify-content: space-between;
    gap: 12px;

    button {
      border: 0;
      border-radius: 50px;
      padding: 8px 15px;
      background-color: #17484a;

      &:hover {
        background-color: #193133;
      }
    }
  }
}
