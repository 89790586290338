@use '../../scss/utils/include-media' as *;
@use '../../scss/variables/themes' as *;
@use '../../scss/variables/colors' as *;

.footer-container {
  width: 100%;
  background-color: $green;
  padding-top: 41px;
  padding-bottom: 21px;

  &-first-line {
    display: flex;
    margin-bottom: 80px;

    @include media('<=large') {
      flex-direction: column;
    }
  }

  &-general {
    display: flex;
    flex-direction: column;
    width: 215px;
  }

  &-general-section {
    display: flex;
    flex-direction: column;
    width: 215px;
  }

  &-sections {
    display: flex;
    margin-left: auto;

    @include media('<=large') {
      flex-direction: column;
      margin-left: 0;
    }
  }

  &-section {
    display: flex;
    flex-direction: column;
    margin-left: 150px;

    @include media('<=large') {
      margin-left: 0;
    }
  }

  &-logo {
    width: 150px;
    margin-bottom: 30px;
  }

  &-section-title {
    font-family: 'Inter';
    font-weight: 800;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 2px;
    color: $teal;
    margin-top: 28px;
    margin-bottom: 20px;
  }

  &-item,
  &-item.nav-link {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: $white;
    margin-bottom: 15px;

    &:hover {
      color: $white;
    }

    &.email {
      text-decoration: underline;
      margin-bottom: 5px;
    }
  }

  &-social-networks {
    display: flex;
    margin-top: 30px;

    img {
      margin-right: 10px;
    }
  }

  &-second-line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;

    img {
      height: 27px;
      filter: opacity(0.5) drop-shadow(0 0 0 #b9c8c9);
    }

    @include media('<=large') {
      flex-wrap: wrap;
      row-gap: 30px;
    }
  }

  &-third-line {
    display: flex;

    p {
      font-family: 'Inter';
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      letter-spacing: 2px;
      color: $white;
      opacity: 60%;
    }
  }
}
