.offcanvas.offcanvas-bottom {
  height: 80px;
  background-color: #808080;

  .offcanvas-body {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
