@import '../../scss/variables/_colors';
@import '../../scss/utils/_include-media';

.details-page-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  border-radius: 10px;
  background-color: rgba($green, 0.15);

  @include media('<=large') {
    height: 32px;
  }

  span {
    color: $green;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;

    @include media('<=large') {
      font-size: 14.4px;
    }
  }
}
