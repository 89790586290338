@font-face {
  font-family: 'Bebas Neue';
  src: local('BebasNeue-Regular'), url(../../fonts/BebasNeue-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Oswald';
  src: local('oswald.regular'), url(../../fonts/oswald.regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Caveat';
  src: local('Caveat-VariableFont'), url(../../fonts/Caveat-VariableFont.ttf) format('truetype');
}
