@import '../../scss/variables/_colors';

.details-page-feedback {
  padding-top: 45px;

  p {
    color: $green;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    margin-bottom: 15px;
  }
}
