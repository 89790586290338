@import '../../scss/variables/_colors';
@import '../../scss/utils/_include-media';

.page-header {
  display: flex;
  align-items: center;

  &-left-side {
    display: flex;
    flex-direction: column;
    margin-right: 50px;

    @include media('<=large') {
      margin-right: 0;
    }

    .page-title {
      color: $green;
      font-family: Inter;
      font-size: 45px;
      font-style: normal;
      font-weight: 600;
      line-height: 95%;
      margin-bottom: 20px;

      @include media('<=large') {
        font-size: 30px;
      }
    }

    .page-description {
      color: $green;
      font-family: Inter;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      margin-bottom: 30px;

      @include media('<=large') {
        font-size: 14px;
      }
    }

    .buttons {
      display: flex;

      & > * {
        margin-right: 20px;
      }
    }
  }

  &-right-side {
    @include media('<=large') {
      display: none;
    }
  }
}
