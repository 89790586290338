@use 'include-media' as *;

.padding-x {
  padding-left: 112px;
  padding-right: 112px;

  @include media('<=large') {
    padding-left: 16px;
    padding-right: 16px;
  }
}
