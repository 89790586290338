@import '../../scss/variables/_colors';

.list-element {
  background-color: $white;
  border-radius: 30px;
  box-shadow: 0px 25px 30px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  width: 300px;

  &-img {
    width: 300px;
    height: auto;
    border-radius: 30px 30px 0 0;
  }

  &-content {
    display: flex;
    flex-direction: column;
    height: 300px;
    padding: 25px;
  }

  &-tags {
    display: flex;
    margin-bottom: 10px;
  }

  &-tag {
    display: flex;
    background-color: $teal;
    margin-right: 5px;
    border-radius: 600px;

    span {
      padding: 5px 10px;
      color: $white;
      font-family: Inter;
      font-size: 9px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%;
      letter-spacing: 0.9px;
      text-transform: uppercase;
      text-align: center;
    }
  }

  &-title {
    margin-bottom: 10px;
    color: $green;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &-description {
    color: $green;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }

  &-button {
    align-self: center;
    margin-top: auto;
  }
}
