@use '../../scss/variables/colors' as *;

.u-banner-outer {
  &-green-theme {
    background-color: $green;
  }

  &-light-theme {
    background-color: $beige;
  }

  &-teal-theme {
    background-color: $teal;
  }

  .u-banner-inner {
    &-green-theme {
      background-color: $green;
    }

    &-light-theme {
      background-color: $beige;
    }

    &-teal-theme {
      background-color: $teal;
    }
  }
}
