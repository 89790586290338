@import '../../scss/variables/_colors';

.accordion-toggle {
  span {
    color: $green;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
  }

  svg path {
    fill: $green;
  }
}
