.custom-bootstrap-form-group {
  margin-bottom: 25px;

  label {
    color: #17484a;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    margin-left: 19px;
    margin-bottom: 5px;
  }

  .custom-input {
    padding: 19px;
    border: 2px solid rgba(23, 72, 74, 0.3);
    border-radius: 999px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 105%;
    color: #17484a;
    outline: none;
    box-shadow: none;
    background-color: transparent;

    &.is-invalid {
      border: 2px solid #cb573a;
      box-shadow: none;
      background-image: none;
    }

    &:focus:not(.is-invalid) {
      border: 2px solid #17484a;
    }

    &::placeholder {
      color: rgba(23, 72, 74, 0.5);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
  }

  .input-group {
    border: 2px solid rgba(23, 72, 74, 0.3);
    border-radius: 999px;

    &:has(.is-invalid) {
      border: 2px solid #cb573a;
    }

    &:focus-within {
      border: 2px solid #17484a;

      &:has(.is-invalid) {
        border: 2px solid #cb573a;
      }
    }

    .custom-input-password {
      padding: 19px;
      border: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 105%;
      color: #17484a;
      outline: none;
      box-shadow: none;
      background-color: transparent;

      &.is-invalid {
        background-image: none;
        border: 0;

        &:focus {
          box-shadow: none;
        }
      }

      &::placeholder {
        color: rgba(23, 72, 74, 0.5);
      }
    }

    .input-group-text {
      background-color: transparent;
      border: 0;
      padding-left: 0;
    }
  }

  .custom-input-select {
    padding: 19px;
    border: 2px solid rgba(23, 72, 74, 0.3);
    border-radius: 999px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 105%;
    color: #17484a;
    outline: none;
    box-shadow: none;
    background-color: transparent;

    &.is-invalid {
      border: 2px solid #cb573a;
      box-shadow: none;

      &:not([multiple]):not([size]) {
        background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e');
      }
    }

    &:focus:not(.is-invalid) {
      border: 2px solid #17484a;
    }

    &::placeholder {
      color: rgba(23, 72, 74, 0.5);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
  }

  .invalid-feedback {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #cb573a;
  }

  .annotation {
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    opacity: 0.6;
    text-decoration: none;
    margin-right: 19px;
    margin-bottom: 5px;
  }

  .form-check {
    padding-left: 0;

    input {
      width: 19px;
      height: 16px;
      margin-left: 0;
    }

    label {
      margin-left: 10px;
    }
  }
}
