@use '../../scss/variables/themes' as *;
@use '../../scss/variables/colors' as *;
@use '../../scss/utils/include-media' as *;

.u-card {
  width: 420px;

  @include media('<=large') {
    width: 320px;
  }

  &.u-card-progress {
    .u-card-body {
      background-color: $white;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
    }

    .u-card-img {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .u-card-title {
      color: $green;
    }

    .u-card-description {
      color: $green;
    }
  }

  &.card {
    // reset inherit properties
    margin-top: 0;
    border: 0;
    overflow: visible;
    white-space: normal;
    background-color: transparent;
    border-radius: 30px;
  }

  & &-img {
    width: 100%;
    border-radius: 30px;
  }

  &-body {
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  &-title {
    margin: 40px 0 20px 0;
    font-family: Inter;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    color: $white;

    @include media('<=large') {
      font-size: 25px;
    }
  }

  &-description {
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-align: justify;
    text-justify: inter-word;
    color: $white;

    @include media('<=large') {
      font-size: 15px;
    }
  }

  &-button-container {
    margin-top: 20px;
    width: 100%;
    display: flex;
  }
}
