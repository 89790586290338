@use '../../scss/variables/colors';

.c-btn-small {
  padding: 4px 8px;
}

.c-btn-medium {
  padding: 8px 16px;
}

.c-btn-large {
  padding: 12px 24px;
}

.c-btn {
  background-color: colors.$blue-01;
  color: colors.$white;
  border: 0;
  border-radius: 32px;

  svg {
    fill: colors.$blue-01;
  }

  &:hover {
    background-color: colors.$blue-00;

    svg {
      fill: colors.$blue-00;
    }
  }

  &:disabled {
    background-color: colors.$gray-600;
  }
}

.c-btn-outline {
  background-color: colors.$white;
  color: colors.$blue-01;
  border: 1px solid colors.$blue-01;
  border-radius: 32px;

  svg {
    fill: colors.$blue-01;
  }

  &:hover {
    color: colors.$blue-00;
    border: 1px solid colors.$blue-00;

    svg {
      fill: colors.$blue-00;
    }
  }

  &:disabled {
    color: colors.$gray-600;
    border: 1px solid colors.$gray-600;
  }
}

.c-btn-link {
  background-color: transparent;
  color: colors.$blue-01;
  border: 0;
  text-decoration: underline;

  svg {
    fill: colors.$blue-01;
  }

  &:hover {
    color: colors.$blue-00;

    svg {
      fill: colors.$blue-00;
    }
  }

  &:disabled {
    color: colors.$gray-600;
  }
}
