.u-accordion-item {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
  padding: 20px;

  .u-accordion-item-toggle {
    width: 100%;
    border: 0;
    padding: 0px;
    background-color: inherit;
    color: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    transition: margin-bottom 0.2s ease-in-out;

    &:has(+ .u-accordion-item-collapse.collapsing),
    &:has(+ .u-accordion-item-collapse.show) {
      margin-bottom: 16px;
    }

    .u-accordion-item-toggle-content {
      font-size: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 14px;

      & > *:last-child {
        margin-bottom: 0;
      }
    }
  }

  .u-accordion-item-collapse {
    .u-accordion-item-body {
      margin: 0;
    }
  }

  &:has(+ .u-accordion-item) {
    margin-bottom: 8px;
  }
}
