@import '../../scss/variables/_colors';
@import '../../scss/utils/_include-media';

.filter-button {
  display: flex;
  align-items: center;
  padding: 10px 25px;
  border-radius: 200px;
  border: 2px solid $green;
  background: $white;

  svg {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    fill: $green;

    @include media('<=large') {
      margin-right: 0;
    }
  }

  p {
    margin: 0;
    color: $green;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 105%;

    @include media('<=large') {
      display: none;
    }
  }
}
