@import '../../scss/variables/_colors';
@import '../../scss/utils/_include-media';

.u-input-search {
  background-color: $white;
  border-radius: 30px;
  border: 2px solid rgba($green, 0.5);
  padding: 10px 25px;

  & > form {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > input {
      border: 0;
      background-color: $white;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 105%;

      @include media('<=large') {
        width: 165px;
      }

      &::placeholder {
        color: rgba($green, 0.5);
      }

      &:focus {
        outline: none;
      }
    }

    & > button {
      padding: 0;
      border: 0;
      margin-left: 5px;
      background-color: transparent;
      display: flex;

      svg {
        fill: rgba($green, 0.5);
      }
    }
  }
}
