@use '../../scss/variables/themes';
@use '../../scss/variables/colors';

.inner-container-new-color {
  background-color: themes.$light-theme-background;

  main {
    background-color: themes.$light-theme-background;
  }
}

.inner-container {
  background-color: colors.$white;

  main {
    background-color: colors.$beige;
  }
}
