@import '../../scss/variables/_colors';
@import '../../scss/utils/_include-media';

.details-page-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;

  @include media('<=large') {
    margin-bottom: 20px;
  }

  &-title {
    color: $teal;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    padding-bottom: 10px;

    @include media('<=large') {
      font-size: 12px;
      letter-spacing: 1.2px;
    }
  }

  &-children {
    padding: 25px;
    background-color: $white;
    border-radius: 20px;
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.02);

    @include media('<=large') {
      padding: 15px;
    }
  }
}
