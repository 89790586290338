@use '../../../scss/variables/themes' as *;
@use '../../../scss/variables/colors' as *;
@use '../../../scss/utils/include-media' as *;

.u-navbar {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  background-color: $beige;

  @include media('<=large') {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .u-navbar-brand .u-navbar-brand-logo {
    width: 120px;
    height: 24px;
  }

  .u-navbar-toggler {
    padding: 12px;
    border-radius: 999px;
    font-size: 0;
    border: 1px solid $green;
    color: $green;

    &:focus {
      box-shadow: none;
    }
  }
}
