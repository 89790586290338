@use '../../scss/variables/colors';

.c-input-search {
  background-color: colors.$blue-04;
  padding: 8px 16px;
  border-radius: 40px;
  border: 1px solid colors.$gray-400;

  div {
    width: 20px;
  }

  input {
    background-color: colors.$blue-04;
    font-size: 15px;

    &::placeholder {
      color: colors.$gray-700;
    }

    &:focus {
      outline: none;
    }
  }
}
