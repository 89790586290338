@use '../../scss/utils/include-media' as *;

.sign-up-confirmation-container {
  @include media('<=medium') {
    padding: 0 16px;
  }

  .content {
    margin-top: 30px;
    width: 384px;

    @include media('<=medium') {
      width: 100%;
    }

    .content-title {
      h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: rgba(23, 72, 74, 0.7);
        margin-bottom: 0;

        @include media('<=medium') {
          font-size: 18px;
        }
      }

      h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 33px;
        color: #17484a;
        margin-top: 8px;
        margin-bottom: 0;
      }
    }

    .content-inputs {
      margin-top: 16px;

      @include media('<=medium') {
        margin-top: 30px;
      }
    }

    .content-button {
      margin-top: 33px;
    }
  }
}
