// Greyscale
$white: #ffffff;
$gray-100: #fcfcfc;
$gray-200: #f8f8f8;
$gray-300: #f0f0f0;
$gray-400: #dbdbdb;
$gray-500: #c9c9c9;
$gray-600: #afafaf;
$gray-700: #6d6d6d;
$gray-800: #282828;
$black: #000000;

// Branding
$blue-00: #007480;
$blue-01: #0099a8;
$blue-02: #6ac3cc;
$blue-03: #b6e2e6;
$blue-04: #e7f5f7;
$yellow-00: #ffd96f;
$yellow-01: #ffeec0;

// Tags
$color-01: #ccebee;
$color-02: #f7e6d6;
$color-03: #f0c8c8;
$color-04: #f7f2c3;
$color-05: #e4f7c3;
$color-06: #c3f7d5;
$color-07: #c3e2f7;
$color-08: #c3ccf7;
$color-09: #eac3f7;
$color-10: #f7c3d9;
$color-11: #c3f7f7;
$color-12: #dcaed5;
$color-13: #cec8de;

// Feedback
$error: #cb573a;

// Beige
$beige-50: #faf9f8;
$beige-100: #f5f4f2;
$beige-150: #f0eeeb;
$beige-200: #ebe9e5;
$beige-250: #e5e4df;
$beige-300: #dbd9d2;
$beige-400: #cecdc5;
$beige-500: #bdbcb2;
$beige-600: #9f9d94;
$beige-700: #77766f;
$beige-800: #504e4a;
$beige-900: #282725;

// Green
$green-50: #f4f5f4;
$green-100: #e9ebe9;
$green-150: #c7d1d0;
$green-200: #95aaa9;
$green-300: #6b898a;
$green-400: #41696a;
$green-500: #17484a;
$green-600: #07383b;
$green-700: #052a2c;
$green-800: #041c1e;
$green-900: #020e0f;

// Blue
$blue-50: #f0f8f9;
$blue-100: #c0e3e5;
$blue-200: #90cdd1;
$blue-300: #60b8be;
$blue-400: #30a2aa;
$blue-500: #008d96;
$blue-600: #007178;
$blue-700: #00555a;
$blue-800: #00383c;
$blue-900: #001c1e;

// Orange
$orange-50: #fef9f0;
$orange-100: #fae7c2;
$orange-200: #f6d595;
$orange-300: #f1c367;
$orange-400: #edb13a;
$orange-500: #e99f0c;
$orange-600: #bb7f0a;
$orange-700: #8c6007;
$orange-800: #5e4005;
$orange-900: #2f2002;

// Red
$red-50: #faeeeb;
$red-100: #f1d0c8;
$red-200: #e7b2a4;
$red-300: #de9381;
$red-400: #d4755d;
$red-500: #cb573a;
$red-600: #9d442d;
$red-700: #703020;
$red-800: #421d13;
$red-900: #140906;

// NEW
$white: #ffffff;
$beige: #f4f3f1;
$teal: #008995;
$green: #17484a;
$yellow: #e99f0c;
