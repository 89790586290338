@import '../../scss/variables/_colors';
@import '../../scss/utils/_include-media';

.list-volunteering-actions-page {
  .results-list {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-around;
    row-gap: 50px;
    margin-top: 40px;
    margin-bottom: 0;

    @include media('<=large') {
      grid-template-columns: auto;
      justify-content: center;
      row-gap: 20px;
    }
  }
}
