@forward '~bootstrap/scss/bootstrap' with (
  $primary: #17484a,
  $danger: #cb573a,
  $font-family-base: 'Inter',
  $font-weight-base: 500,
  $headings-font-family: 'Inter',
  $headings-font-weight: 600
);
@forward 'input';
@forward 'button';
@forward 'offcanvas';
