@import '../../scss/variables/_colors';

.c-title {
  color: $green;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 95%;
}

.c-close-icon {
  width: 24px;
  height: 24px;
  fill: $green;
  cursor: pointer;

  &:hover {
    fill: $green;
  }
}
