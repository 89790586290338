@import '../../scss/utils/_include-media';
@import '../../scss/variables/_colors';

.user-profile-container {
  background-color: $beige;

  & > div {
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media('<=large') {
      width: 100%;
    }

    & > h2 {
      color: $green;
      font-family: Inter;
      font-size: 45px;
      font-style: normal;
      font-weight: 600;
      line-height: 95%;
      text-align: center;
      margin-bottom: 30px;

      @include media('<=large') {
        font-size: 25px;
        margin-bottom: 15px;
      }
    }

    & > h3 {
      color: $teal;
      font-family: Inter;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 95%;
      margin-bottom: 50px;

      @include media('<=large') {
        font-size: 20px;
      }
    }
  }

  .user-profile-submit-button {
    width: 100%;
    display: block;
    text-align: center;
    margin-top: 25px;
  }
}
