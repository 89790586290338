.c-form {
  overflow: hidden;
  height: 94%;
}

.c-filter-sub-title {
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  margin-bottom: 10px;
}

.c-filter {
  height: 88%;
  overflow-y: auto;
}

.c-option {
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 10px;
}

.submit-btn {
  margin-left: 20px;
}
