@use 'colors';

// Light theme
$light-theme-background: #f5f4f2;
$light-theme-primary: #17484a;
$light-theme-secondary: #5a7c7d;
$light-theme-tertiary: #859e9e;
$light-theme-accent: #edb13a;
$light-theme-brand: #008d96;
$light-theme-fine: #dfe3e1;
$light-theme-offset: #eeeeec;
$light-theme-highlight: #faf9f8;
$light-theme-error: #cb573a;
$light-theme-top-bar: #e9ebe9;

// Green theme
$green-theme-background: #17484a;
$green-theme-primary: #f5f4f2;
$green-theme-secondary: #adc0bf;
$green-theme-tertiary: #7e9d9d;
$green-theme-accent: #edb13a;
$green-theme-brand: #008d96;
$green-theme-fine: #1f575a;
$green-theme-offset: #0f4b4e;
$green-theme-highlight: #37696a;
$green-theme-error: #cb573a;
$green-theme-top-bar: #e9ebe9;

// Dark theme
$dark-theme-background: #020e0f;
$dark-theme-primary: #f5f4f2;
$dark-theme-secondary: #acafae;
$dark-theme-tertiary: #7b8180;
$dark-theme-accent: #edb13a;
$dark-theme-brand: #008d96;
$dark-theme-fine: #1a2526;
$dark-theme-offset: #091516;
$dark-theme-highlight: #333c3c;
$dark-theme-error: #cb573a;
$dark-theme-top-bar: #e9ebe9;
