@use '../../scss/variables/colors';

.c-tag {
  padding: 4px 8px;
  font-size: 11px;
  border-radius: 24px;
}

.c-tag-filter {
  padding: 8px 16px;
  border-radius: 24px;
  border: 1px solid colors.$black;
}

.c-tag-filter-close-icon {
  fill: colors.$black;
  cursor: pointer;
  width: 16px;
}

.c-tag-user-tag-close-icon {
  fill: colors.$gray-700;
  cursor: pointer;
  width: 13px;
  margin-left: 5px;
}
