@use '../../scss/utils/include-media' as *;

.navigation-bar {
  width: 100%;
  height: 80px;
  padding: 28px 112px;
  display: flex;
  justify-content: space-between;

  @include media('<=medium') {
    height: 56px;
    padding: 16px;
  }

  .logo {
    img {
      width: 121px;
      height: 24px;

      @include media('<=medium') {
        width: 120px;
      }
    }
  }
}
