@import '../../scss/utils/_include-media';
@import '../../scss/variables/_colors';

.sign-in-container {
  @include media('<=large') {
    padding: 25px;
  }

  .go-back {
    display: flex;
    text-decoration: none;
    margin-left: 112px;
    margin-bottom: 80px;

    @include media('<=large') {
      display: none;
    }

    svg {
      width: 10px;
      height: 10px;
      margin-right: 5px;

      path {
        fill: $green;
      }
    }

    span {
      color: $green;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: 1px;
      text-decoration: none;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-bottom: 50px;

      @include media('<=large') {
        width: 230px;
        margin-bottom: 30px;
      }
    }

    .title {
      color: $teal;
      font-family: Inter;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 95%;
      margin-bottom: 50px;

      @include media('<=large') {
        font-size: 25px;
      }
    }

    form {
      width: 450px;

      @include media('<=large') {
        width: 100%;
      }
    }

    button {
      width: 100%;
      display: inline-block;
      text-align: center;
      margin-top: 5px;
    }
  }
}

.create-account {
  margin-top: 20px;
  margin-bottom: 0;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 105%;

  a {
    font-style: normal;
    font-weight: 700;
    color: #008d96;
  }
}

.sso-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding-bottom: 20px;

  span {
    color: $green;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
}
