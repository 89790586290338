.custom-bootstrap-button {
  padding: 12px 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border-radius: 999px;

  &.btn-primary {
    background-color: #17484a;
    color: #f5f4f2;
    border: 0;

    &:hover {
      background-color: rgba(23, 72, 74, 0.7);
    }

    &:active {
      background-color: rgba(23, 72, 74, 0.5) !important;
    }

    &:disabled {
      opacity: 0.2;
    }
  }

  &.btn-outline-primary {
    color: #17484a;
    background-color: transparent;
    border: 1px solid rgba(23, 72, 74, 0.1);

    &:hover {
      background-color: rgba(23, 72, 74, 0.03);
    }

    &:active {
      background-color: rgba(23, 72, 74, 0.1) !important;
      border: 1px solid rgba(23, 72, 74, 0.1);
      color: #17484a;
    }

    &:disabled {
      opacity: 0.2;
    }
  }

  &.btn-sm {
    font-size: 14px;
    padding: 7px 12px 8px;
  }

  &.btn-lg {
    padding: 16px 20px;
  }
}
