@use '../../scss/variables/colors';

.c-input {
  background-color: colors.$white;
  padding: 8px 16px;
  border-radius: 40px;
  border: 1px solid colors.$gray-400;
  font-size: 15px;
  color: colors.$black;

  &::placeholder {
    color: colors.$gray-700;
  }

  &:focus,
  &:hover {
    &:enabled {
      outline: none;
      box-shadow: 0px 3px 15px rgba(0, 153, 168, 0.1);
      border: 0;
    }
  }

  &:disabled {
    &::placeholder {
      color: colors.$gray-500;
    }
  }
}

.c-disabled {
  color: colors.$gray-500;
}

.c-description-and-error {
  font-size: 11px;
}

.c-input-error {
  box-shadow: 0px 4px 24px rgba(254, 95, 95, 0.1);

  &::placeholder {
    color: colors.$error;
  }
}

.c-error {
  color: colors.$error;
}
