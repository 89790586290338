@import '../../scss/variables/_colors';
@import '../../scss/utils/_include-media';

.go-back {
  display: flex;
  text-decoration: none;
  margin-bottom: 25px;

  svg {
    width: 10px;
    height: 10px;
    margin-right: 5px;

    path {
      fill: $green;
    }
  }

  span {
    color: $green;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 1px;
    text-decoration: none;
  }
}

.details-page-content {
  display: flex;

  @include media('<=large') {
    flex-direction: column;
  }

  &-left {
    flex-grow: 1;

    img {
      border-radius: 600px;
      width: 160px;
      height: 160px;
    }
  }

  &-right {
    width: 300px;
    margin-left: 50px;

    @include media('<=large') {
      width: 100%;
      margin-left: 0;
    }

    img {
      border-radius: 600px;
      width: 300px;
      height: 300px;
      margin-bottom: 50px;
    }

    button {
      display: inline-block;
      width: 100%;
      text-align: center;
    }
  }
}

.details-page-tags {
  div {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.details-page-links {
  button {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
