@use '../../scss/utils/include-media' as *;
@use '../../scss/variables/themes' as *;
@use '../../scss/variables/colors' as *;

.u-dropdown {
  &.show {
    .u-dropdown-menu.show {
      border-radius: 20px;
      overflow: hidden;
      padding: 0;

      .u-dropdown-item {
        color: $green;
        padding: 8px 18px;

        &:active,
        &:hover {
          color: $teal;
          background-color: transparent;
        }

        &:first-child {
          padding-top: 16px;
        }

        &:last-child {
          padding-bottom: 16px;
        }
      }
    }
  }

  .u-dropdown-toggle {
    text-decoration: none;

    &:active,
    &:hover {
      color: $teal;
    }
  }
}
