@use '../variables/colors';

html,
body,
#root,
#outer-container {
  height: 100%;
}

#page-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

.subtitle-s2 {
  font-family: 'Oswald', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  text-transform: uppercase;
}

.paragraph {
  font-family: 'Oswald', serif;
  font-style: normal;
  font-weight: 300;

  &-p1 {
    font-size: 16px;

    &-strong {
      font-weight: 500;
    }
  }

  &-p2 {
    font-size: 13px;

    &-strong {
      font-weight: 500;
    }
  }
}

.dropdown-toggle::after {
  color: colors.$white;
}

.input-error {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  color: rgba(255, 0, 0, 0.84);
  margin-top: 3px;
}

.sui-search-box__text-input {
  box-shadow: none;
  padding: 0;
  font-family: 'Oswald';
  font-size: 15px;

  &:focus {
    box-shadow: none;
    border: 1px solid #3a56e4;
  }
}
