@use '../../scss/variables/colors' as *;
@use '../../scss/utils/include-media' as *;

.user-courses-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $beige;
  padding: 70px;

  @include media('<=large') {
    padding: 40px;
  }

  h2 {
    color: $green;
    margin-bottom: 40px;
    font-family: Inter;
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    text-align: center;

    @include media('<=large') {
      font-size: 25px;
      margin-bottom: 25px;
    }
  }

  &-list {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: space-between;
    row-gap: 50px;
    margin-bottom: 40px;

    @include media('<=large') {
      grid-template-columns: auto;
      justify-content: center;
      row-gap: 20px;
      margin-bottom: 25px;
    }
  }

  .user-courses-container-no-courses {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: $green;

    @include media('<=large') {
      font-size: 20px;
    }
  }
}
