@import '../../scss/variables/_colors';
@import '../../scss/utils/_include-media';

.details-page-text {
  &-title {
    color: $green;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    padding-top: 10px;
    padding-bottom: 10px;

    @include media('<=large') {
      font-size: 14.4px;
    }
  }

  &-label {
    color: $green;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    padding-bottom: 10px;

    @include media('<=large') {
      font-size: 14.4px;
    }
  }
}
