@use '../../scss/utils/include-media' as *;
@use '../../scss/variables/themes' as *;
@use '../../scss/variables/colors' as *;

.initial-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $beige;
  border-radius: 0px 0px 100px 100px;

  @include media('<=large') {
    border-radius: 0px 0px 30px 30px;
  }

  &-img {
    margin-top: 75px;

    @include media('<=large') {
      margin-top: 50px;

      img {
        width: 170px;
      }
    }
  }

  &-title {
    margin-top: 50px;
    color: $green;
    text-align: center;
    font-family: 'Inter';
    font-size: 77.507px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;

    @include media('<=large') {
      margin-top: 20px;
      font-size: 34.878px;
    }

    p {
      margin-bottom: 0;
    }

    .accent {
      color: $teal;
      font-family: 'Caveat';
      font-size: 125.603px;
      font-style: normal;
      font-weight: 700;
      line-height: 95%;
      letter-spacing: -5.024px;

      @include media('<=large') {
        font-size: 56.521px;
        letter-spacing: -2.261px;
      }
    }
  }

  &-subtitle {
    color: $green;
    text-align: center;
    font-family: 'Inter';
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 105%;
    margin-top: 5px;
    max-width: 720px;

    @include media('<=large') {
      font-size: 14px;
    }

    p {
      margin-bottom: 0;
    }
  }

  &-imgs-banner {
    margin: 100px 0 150px 0;
    display: flex;

    @include media('<=large') {
      margin: 40px 0 60px 0;
      flex-direction: column;
    }

    &-line {
      display: flex;
    }

    &-item {
      width: 325px;
      height: 325px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      background-size: contain;

      @include media('<=large') {
        width: 170px;
        height: 170px;
      }

      &:hover {
        .initial-banner-imgs-banner-item-description {
          display: block;

          @include media('<=large') {
            display: none;
          }
        }
      }

      img {
        width: 50px;
        height: 50px;

        @include media('<=large') {
          width: 26px;
          height: 26px;
        }
      }

      &-title {
        color: $white;
        font-family: 'Caveat';
        font-size: 80px;
        font-style: normal;
        font-weight: 700;
        line-height: 95%;
        letter-spacing: -3.2px;

        @include media('<=large') {
          font-size: 41.814px;
          letter-spacing: -1.673px;
        }
      }

      &-description {
        display: none;
        width: 240px;
        color: $white;
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: 105%;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 15px;

        @include media('<=large') {
          font-size: 9px;
        }
      }

      &-know-more {
        color: $white;
        font-family: 'Inter';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 105%;
        margin-top: 15px;

        @include media('<=large') {
          font-size: 9.408px;
        }
      }

      &-1 {
        background-image: url('../../images/homepage/banner/img1.png');

        &:hover {
          background-image: url('../../images/homepage/banner/img1-hover.png');
        }
      }

      &-2 {
        background-image: url('../../images/homepage/banner/img2.png');

        &:hover {
          background-image: url('../../images/homepage/banner/img2-hover.png');
        }
      }

      &-3 {
        background-image: url('../../images/homepage/banner/img3.png');

        &:hover {
          background-image: url('../../images/homepage/banner/img3-hover.png');
        }
      }

      &-4 {
        background-image: url('../../images/homepage/banner/img4.png');

        &:hover {
          background-image: url('../../images/homepage/banner/img4-hover.png');
        }
      }
    }
  }
}

.u-impact-actions-banner {
  padding-top: 80px;
  margin-top: -100px;

  @include media('<=large') {
    padding-top: 32px;
    padding-bottom: 64px;
  }

  .u-impact-actions-header {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: 80px;

    @include media('<=large') {
      margin-bottom: 35px;
    }

    &-title {
      display: flex;
      align-items: baseline;
      width: 100%;
      margin-bottom: 20px;

      h2 {
        color: $white;
        font-family: 'Caveat';
        font-size: 100px;
        font-style: normal;
        font-weight: 700;
        line-height: 95%;
        letter-spacing: -4px;
        margin-bottom: 0;
        margin-right: 20px;

        @include media('<=large') {
          font-size: 60px;
          letter-spacing: -2.4px;
        }
      }

      img {
        width: 30px;
        height: 30px;
      }
    }

    &-subtitle {
      display: flex;
      justify-content: space-between;

      @include media('<=large') {
        flex-direction: column;
      }

      h2 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 50px;
        line-height: 47.5px;
        color: $white;
        margin-bottom: 0;

        @include media('<=large') {
          font-size: 30px;
          line-height: 95%;
        }
      }

      .u-impact-actions-header-buttons {
        display: flex;

        @include media('<=large') {
          margin-top: 15px;
        }

        button {
          align-self: center;
        }

        .u-impact-banner-scroll-buttons {
          display: flex;

          button:first-of-type {
            margin-left: 10px;
            margin-right: 10px;
          }

          svg {
            path {
              stroke: $white;
            }
          }
        }
      }
    }
  }

  .u-impact-actions-banner-scroll-container {
    margin-top: 48px;
    overflow-x: hidden;
    white-space: nowrap;

    .u-card-outer-container {
      display: inline-block;

      .u-card-inner-container {
        display: flex;
        justify-content: space-between;
        gap: 30px;
      }
    }
  }

  .u-impact-actions-banner-local-and-simple-messages {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;

    @include media('<=large') {
      margin-top: 60px;
    }

    .impact-cards {
      display: flex;

      @include media('<=large') {
        flex-direction: column;
      }
    }

    .local-simple-impact {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 500px;
      padding: 70px 40px 50px 40px;
      border-radius: 20px;
      background-color: $white;
      box-shadow: 0px 25px 30px 0px rgba(0, 0, 0, 0.08);

      @include media('<=large') {
        width: 340px;
      }

      &:first-of-type {
        margin-right: 70px;

        @include media('<=large') {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }

      & > img {
        width: 169px;
        margin-bottom: 50px;

        @include media('<=large') {
          width: 115px;
          margin-bottom: 35px;
        }
      }

      & > h5 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 95%;
        color: $teal;
        margin-bottom: 35px;

        @include media('<=large') {
          font-size: 27.2px;
          margin-bottom: 25;
        }
      }

      & > p {
        height: 140px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        color: $green;
        margin-bottom: 20px;
        text-align: center;

        @include media('<=large') {
          height: auto;
          font-size: 13.6px;
        }
      }
    }
  }
}

.u-opinion-leaders-banner {
  padding-top: 80px;
  padding-bottom: 150px;
  margin-top: -100px;

  @include media('<=large') {
    padding-bottom: 80px;
    margin-top: -120px;
  }

  .u-opinion-leaders-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;

    @include media('<=large') {
      margin-top: 40px;
    }

    &-title {
      display: flex;
      align-items: baseline;
      margin-bottom: 20px;

      h3 {
        color: $teal;
        font-family: 'Caveat';
        font-size: 100px;
        font-style: normal;
        font-weight: 700;
        line-height: 95%;
        letter-spacing: -4px;
        margin-bottom: 0;
        margin-right: 20px;

        @include media('<=large') {
          font-size: 60px;
          letter-spacing: -2.4px;
        }
      }

      img {
        width: 50px;
        height: 50px;

        @include media('<=large') {
          width: 30px;
          height: 30px;
        }
      }
    }

    &-subtitle {
      width: 863px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media('<=large') {
        width: 100%;
      }

      h3 {
        margin-bottom: 20px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 60px;
        line-height: 95%;
        text-align: center;
        color: $green;

        @include media('<=large') {
          font-size: 25px;
        }
      }

      p {
        margin-bottom: 30px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 105%;
        text-align: center;
        color: $green;

        @include media('<=large') {
          font-size: 14px;
          margin-bottom: 15px;
        }
      }
    }
  }

  .u-opinion-leaders-banner-list {
    margin-top: 70px;

    @include media('<=large') {
      overflow-x: hidden;
      white-space: nowrap;
    }

    .u-opinion-leaders-banner-list-outer {
      .u-opinion-leaders-banner-list-inner {
        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: space-around;

        @include media('<=large') {
          grid-template-columns: auto auto;
        }

        .u-opinion-leader {
          margin-bottom: 65px;
          width: 350px;
          display: flex;
          flex-direction: column;
          align-items: center;

          @include media('<=large') {
            width: 160px;
          }

          .u-opinion-leader-image {
            margin-bottom: 25px;
            width: 100%;

            &-even {
              border-radius: 0px 0px 999px 999px;
            }

            &-odd {
              border-radius: 999px 999px 999px 0px;
            }
          }

          .u-opinion-leader-name {
            margin-bottom: 15px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 95%;
            text-align: center;
            color: $green;

            @include media('<=large') {
              font-size: 13.51px;
            }
          }
        }
      }
    }
  }
}

.u-courses-banner {
  padding-top: 150px;
  padding-bottom: 112px;

  @include media('<=large') {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &-title {
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;

    h2 {
      color: $white;
      font-family: 'Caveat';
      font-size: 100px;
      font-style: normal;
      font-weight: 700;
      line-height: 95%;
      letter-spacing: -4px;
      margin-bottom: 0;
      margin-right: 20px;

      @include media('<=large') {
        font-size: 60px;
        letter-spacing: -2.4px;
      }
    }

    img {
      width: 50px;
      height: 50px;

      @include media('<=large') {
        width: 30px;
        height: 30px;
      }
    }
  }

  &-usercourses {
    margin-bottom: 100px;

    &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 80px;

      @include media('<=large') {
        flex-direction: column;
        margin-bottom: 35px;
      }

      h2 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 60px;
        line-height: 95%;
        color: $white;
        margin-bottom: 0;

        @include media('<=large') {
          font-size: 25px;
        }
      }

      &-buttons {
        display: flex;

        button {
          align-self: center;

          &:first-of-type {
            margin-right: 10px;
          }
        }

        svg {
          path {
            stroke: $white;
          }
        }
      }
    }

    &-container {
      overflow-x: hidden;
      white-space: nowrap;

      @include media('<=large') {
        margin: 0;
        margin-top: 48px;
      }

      .u-courses-banner-usercourses-outter-container {
        display: inline-block;

        .u-courses-banner-usercourses-inner-container {
          display: flex;
          justify-content: space-between;
          gap: 30px;
        }
      }
    }
  }

  &-learnworlds {
    &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 80px;

      @include media('<=large') {
        flex-direction: column;
        margin-bottom: 35px;
      }

      h2 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 60px;
        line-height: 95%;
        color: $white;
        margin-bottom: 0;

        @include media('<=large') {
          font-size: 25px;
        }
      }

      &-buttons {
        display: flex;

        @include media('<=large') {
          margin-top: 15px;
        }

        button {
          align-self: center;
        }

        .u-courses-learnworlds-scroll-buttons {
          display: flex;

          button:first-of-type {
            margin-left: 10px;
            margin-right: 10px;
          }

          svg {
            path {
              stroke: $white;
            }
          }
        }
      }
    }

    &-container {
      overflow-x: hidden;
      white-space: nowrap;

      @include media('<=large') {
        margin: 0;
        margin-top: 48px;
      }

      .u-courses-banner-learnworlds-outter-container {
        display: inline-block;

        .u-courses-banner-learnworlds-inner-container {
          display: flex;
          justify-content: space-between;
          gap: 30px;

          .u-courses-banner-learnworlds-more-information {
            // reset inherit properties
            margin-top: 0;
            overflow: visible;
            white-space: normal;

            width: 420px;
            border-radius: 20px;
            background-color: $white;
            padding: 80px 50px;
            display: flex;
            flex-direction: column;

            @include media('<=large') {
              width: 320px;
              padding: 50px 30px;
            }

            &-title {
              font-size: 45px;
              font-weight: 700;
              line-height: 90%;
              color: $green;

              @include media('<=large') {
                font-size: 25px;
              }
            }

            &-subtitle {
              font-size: 18px;
              font-weight: 400;
              line-height: 140%;
              color: $green;
              text-align: justify;
              text-justify: inter-word;
              margin-top: 20px;

              @include media('<=large') {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
}

.u-dreams-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  padding-bottom: 120px;

  @include media('<=large') {
    padding-top: 32px;
    padding-bottom: 64px;
  }

  .u-dreams-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
      display: flex;
      align-items: baseline;
      margin-bottom: 20px;

      h3 {
        color: $yellow;
        font-family: 'Caveat';
        font-size: 100px;
        font-style: normal;
        font-weight: 700;
        line-height: 95%;
        letter-spacing: -4px;
        margin-bottom: 0;
        margin-right: 20px;

        @include media('<=large') {
          font-size: 60px;
          letter-spacing: -2.4px;
        }
      }

      img {
        width: 50px;
        height: 50px;

        @include media('<=large') {
          width: 30px;
          height: 30px;
        }
      }
    }

    &-subtitle {
      width: 990px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media('<=large') {
        width: 100%;
      }

      h3 {
        margin-bottom: 35px;
        text-align: center;
        font-family: 'Inter';
        font-size: 45px;
        font-style: normal;
        font-weight: 600;
        line-height: 115%;
        color: $green;

        @include media('<=large') {
          font-size: 27px;
        }

        .accent {
          color: $yellow;
          font-family: Caveat;
          font-size: 85px;
          font-style: normal;
          font-weight: 700;
          line-height: 50%;
          letter-spacing: -3.4px;

          @include media('<=large') {
            font-size: 51px;
            letter-spacing: -2.04px;
          }
        }

        .underline {
          text-decoration: underline;
          text-decoration-color: $yellow;
        }
      }

      .u-dreams-header-button {
        margin-bottom: 50px;
      }
    }
  }

  .u-dreams-videos {
    position: relative;
    height: 1092px;
    width: 1273px;

    @include media('<=large') {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-video {
      position: absolute;
      border-radius: 15px;
      overflow: hidden;

      @include media('<=large') {
        margin-bottom: 15px;
      }

      &-1 {
        width: 697px;
        height: 392px;
        top: 17px;
        left: 38px;
        z-index: 2;

        @include media('<=large') {
          width: 340px;
          height: 191px;
          position: relative;
          top: 0;
          left: 0;
        }
      }

      &-2 {
        width: 708px;
        height: 398px;
        top: 227px;
        left: 565px;
        z-index: 3;

        @include media('<=large') {
          width: 340px;
          height: 191px;
          position: relative;
          top: 0;
          left: 0;
        }
      }

      &-3 {
        width: 708px;
        height: 398px;
        top: 489px;
        left: 0;
        z-index: 2;

        @include media('<=large') {
          width: 340px;
          height: 191px;
          position: relative;
          top: 0;
          left: 0;
        }
      }

      &-4 {
        width: 640px;
        height: 360px;
        top: 706px;
        left: 540px;
        z-index: 1;

        @include media('<=large') {
          width: 340px;
          height: 191px;
          position: relative;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
