@import '../../scss/variables/_colors';
@import '../../scss/utils/_include-media';

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 40px;
  background-color: $white;
  border-radius: 200px;
  padding: 10px;

  @include media('<=large') {
    margin-top: 40px;
    margin-bottom: 20px;
  }
}

.results-list {
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: space-between;
  row-gap: 50px;
  margin-bottom: 70px;

  @include media('<=large') {
    grid-template-columns: auto;
    justify-content: center;
    row-gap: 20px;
  }
}
