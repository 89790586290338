@use '../../../../scss/utils/include-media' as *;
@use '../../../../scss/variables/themes' as *;
@use '../../../../scss/variables/colors' as *;

.u-navbar-offcanvas {
  width: 100% !important;
  border-left: 0 !important;
  background-color: $beige !important;
  color: $green;

  @include media('<=large') {
    padding: 8px 16px;
  }

  .u-navbar-offcanvas-header {
    padding: 0;

    .u-navbar-offcanvas-header-title {
      .u-navbar-offcanvas-header-logo {
        width: 120px;
        height: 24px;
      }
    }

    .u-navbar-offcanvas-header-close-button {
      padding: 12px;
      border-radius: 999px;
      font-size: 0;
      background-color: transparent;
      border: 1px solid $green;
      color: $green;
    }
  }

  .u-navbar-offcanvas-body {
    justify-content: space-between;
    align-items: center;

    .u-navbar-offcanvas-body-nav {
      display: flex;
      align-items: center;
      gap: 32px;

      .nav-link {
        color: $green;

        &:active,
        &:hover {
          color: $teal;
        }
      }
    }
  }

  .u-navbar-offcanvas-body-expanded {
    padding: 0;
    margin-top: 48px;

    .u-navbar-offcanvas-body-expanded-navigation {
      .u-nav-item {
        padding: 12px 0;
        color: rgba(126, 157, 157, 0.5);
      }

      .u-nav-link {
        padding: 12px 0;
        color: $green;

        &:active,
        &:hover {
          color: $teal;
        }
      }

      .u-nav-item-divider {
        margin: 12px 0;
        border: 1px solid $green;
      }
    }
  }
}
