@import '../../scss/variables/_colors';
@import '../../scss/utils/_include-media';

.details-page-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  @include media('<=large') {
    margin-bottom: 30px;
  }

  &-section-title {
    color: $teal;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-top: 25px;
    margin-bottom: 15px;

    @include media('<=large') {
      font-size: 12px;
      letter-spacing: 1.2px;
    }
  }

  &-title {
    color: $green;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    margin-bottom: 20px;

    @include media('<=large') {
      font-size: 30px;
      margin-bottom: 15px;
    }
  }

  &-subtitle {
    color: $green;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    margin-bottom: 20px;

    @include media('<=large') {
      font-size: 18.75px;
      margin-bottom: 15px;
    }
  }

  &-description {
    color: $green;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;

    @include media('<=large') {
      font-size: 14px;
    }
  }
}
