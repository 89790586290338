@use '../../../../scss/utils/include-media' as *;
@use '../../../../scss/variables/themes' as *;
@use '../../../../scss/variables/colors' as *;

.u-navbar-dropdown-profile {
  width: 136px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &.show {
    .navbar-dropdown-profile-dropdown-menu.show {
      border-radius: 20px;
      overflow: hidden;
      padding: 16px;

      .navbar-dropdown-profile-dropdown-item {
        color: $green;
        padding: 8px 0;

        &:active,
        &:hover {
          color: $teal;
          background-color: transparent;
        }

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }

        &-divider {
          margin: 0;
        }
      }
    }
  }

  .u-navbar-dropdown-profile-toggle {
    background-color: transparent;
    padding: 12px;
    border-radius: 999px;
    font-size: 0;
    border: 1px solid $green;
    color: $green;
  }
}
